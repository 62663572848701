<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <div class="mt-8 px-5 py-5 elevation-6 rounded">
          <div class="d-flex justify-space-between">
            <div class="d-flex action">
                <v-sheet
                color="primary "
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click="vue='voir',create.save=false,update.save=false"
                style="cursor: pointer"
                >
                <v-icon size="32">mdi-clipboard-text</v-icon>
                <span class="text-caption text-uppercase">Voir</span>
                </v-sheet> 
                <v-sheet
                v-if="$store.state.currentUser.user.autorisation<=1"
                color="primary "
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click="vue='creer',create.save=false,update.save=false"
                style="cursor: pointer"
                >
                <v-icon size="32">mdi-pencil</v-icon>
                <span class="text-caption text-uppercase">Créer</span>
                </v-sheet> 
            </div>
            <div class="d-flex save">
                <v-sheet
                v-if="$store.state.currentUser.user.autorisation<=1"
                :color="trash.save?'red':'grey'"
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click="supprimer()"
                style="cursor: pointer"
                >
                <v-icon  size="32">mdi-delete</v-icon>
                <span class="text-caption text-uppercase">Supprimer</span>
                </v-sheet>        

                <v-sheet
                :color="create.save || update.save?'indigo':'grey'"
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click="save()"
                style="cursor: pointer"
                >
                <v-icon  size="32">mdi-content-save</v-icon>
                <span class="text-caption text-uppercase">Garder</span>
                </v-sheet>
            </div>   
          </div>
          <div v-if="vue=='voir'">
        <v-simple-table>
          <thead>
            <tr>
              <th class="primary--text">ID</th>
              <th class="primary--text">Prenom</th>
              <th class="primary--text">Nom</th>
              <th class="primary--text">E-mail</th>
              <th class="primary--text">Adresse</th>
              <th class="primary--text">statut</th>
              <th class="primary--text">Autorisation</th>
              <th v-if="$store.state.currentUser.user.autorisation<=1" class="primary--text">Select</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item,index) in arrayUser" :key="index" @dblclick="vue='modifier',assignUpdate(item), itemUpdate=item">
              <td>{{index+1}}</td>
              <td>{{ item.prenom }}</td>
              <td>{{ item.nom }}</td>
              <td>{{ item.email }}</td>
              <td>{{ `${item.adresse} ${item.cp} ${item.ville}` }}</td>
              <td>{{ optionsStatut.find(el=>el.value==item.statut).text }}</td>
              <td>{{ optionsAutorisation.find(el=>el.value==item.autorisation).text }}</td>
              <td>
                <v-checkbox
                v-if="$store.state.currentUser.user.autorisation<=1 && item.autorisation!=1"
                  :key="index"
                  v-model="selected"
                  :value="item.ref_user"
                  @change="controlSelect()"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
     </div>
          <div v-if="vue=='creer'">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                    <v-text-field  label="Prénom"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.prenom"
                    ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Nom"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.nom"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Téléphone"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.tel"
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Addresse Email"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.email"
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-text-field
                    label="Mot de passe"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.mdp"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Adresse"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.adresse"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Code Postal"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.cp"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Ville"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.ville"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    class="mb-0"
                    label="Description"
                    filled
                    hide-details
                    @change="controlSave()"
                    @keyup="controlSave()"
                    v-model="create.description"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    label="Statut"
                    @change="controlSave()"
                    v-model="create.statut"
                    :items="optionsStatut"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    label="Autorisation"
                    @change="controlSave()"
                    v-model="create.autorisation"
                    :items="optionsAutorisation"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div v-if="vue=='modifier'">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                    <v-text-field @change="controlSave()"
                    @keyup="controlSave()"  label="Prénom" v-model="update.prenom"></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Nom"
                    v-model="update.nom"
                    @change="controlSave()"
                    @keyup="controlSave()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                  v-model="update.tel"
                    label="Téléphone"
                    @change="controlSave()"
                    @keyup="controlSave()"
                  />
                </v-col>
                
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                  v-model="update.email"
                    label="Addresse Email"
                    @change="controlSave()"
                    @keyup="controlSave()"
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-text-field
                  v-model="update.mdp"
                    label="Mot de passe"
                    @change="controlSave()"
                    @keyup="controlSave()"
                    type="password"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                  v-model="update.adresse"
                    label="Adresse"
                    @change="controlSave()"
                    @keyup="controlSave()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                  v-model="update.cp"
                    label="Code Postal"
                    @change="controlSave()"
                    @keyup="controlSave()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                  v-model="update.ville"
                    label="Ville"
                    @change="controlSave()"
                    @keyup="controlSave()"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    class="mb-0"
                    label="Description"
                    filled
                    hide-details
                    v-model="update.description"
                    @change="controlSave()"
                    @keyup="controlSave()"
                  />
                </v-col>
                <v-col
                  v-if="$store.state.currentUser.user.autorisation <= 1"
                  cols="12"
                  md="4"
                >
                  <v-select
                  v-model="update.statut"
                    label="Statut"
                    :items="optionsStatut"
                    @change="controlSave()"
                  />
                </v-col>
                <v-col
                  v-if="$store.state.currentUser.user.autorisation <= 1"
                  cols="12"
                  md="4"
                >
                  <v-select
                  v-model="update.autorisation"
                    label="Autorisation"
                    :items="optionsAutorisation"
                    @change="controlSave()"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          :avatar="require('@/assets/admin_avatar.jpg')"
        >
        <!-- <base-material-card
          class="v-card-profile"
          :avatar="this.$store.state.currentUser.user.statut==1? require('@/assets/admin_avatar.jpg'):this.$store.state.currentUser.user.prenom.substr(0,1)"
        > -->
          <v-card-text class="text-center">
            <h6 class="text-h4 mb-1 grey--text">
              {{ optionsStatut.find(el=>el.value==$store.state.currentUser.user.statut).text }}
            </h6>

            <h4 class="text-h3 font-weight-light mb-3 black--text">
              {{$store.state.currentUser.user.prenom}} {{ $store.state.currentUser.user.nom }}
            </h4>

            <p class="font-weight-light grey--text">
              {{ $store.state.currentUser.user.description }}
              <!-- À travers son parcours exceptionnel d'athlète de haut niveau et d'entraîneur national, Ludovic Vo a mis en place des méthodes d'entraînement adaptés aux différents publics.
              La philosophie est de tendre vers la performance et le dépassement de soi... -->
            </p>

            <!-- <v-btn
              color="primary "
              rounded
              @click="vue='modifier'"
            >
              Modifier
            </v-btn> -->
            <v-btn
              color="red"
              rounded
              class="mr-0"
              @click="deconnexion()"
            >
              Deconnexion
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapActions} from "vuex"
  import Users from "@/services/User"
  export default {
    mounted(){
      // console.log("this.$store.state.currentUser==>",this.$store.state.currentUser)
      this.getAllUser()
    },
  data: () => ({
    selected:[],
    optionsStatut:[{text:'Manager',value:'1'},{text:'Entraineur',value:'2'}],
    optionsAutorisation:[{text:'Admin',value:'1'},{text:'Visiteur',value:'2'}],
    arrayUser:[],
    vue:"voir",
    trash:{
      save:false
    },
    create:{
      save:false,
      prenom:"",
      nom:"",
      tel:"",
      email:"",
      mdp:"",
      adresse:"",
      cp:"",
      ville:"",
      description:"",
      statut:"2",
      autorisation:"2",
    },
    update:{
      save:false,
      prenom:"",
      nom:"",
      tel:"",
      email:"",
      mdp:"",
      adresse:"",
      cp:"",
      ville:"",
      description:"",
      statut:"",
      autorisation:"",
    },
    itemUpdate:{}
  }),
  methods:{
   ...mapActions(["LogIn"]),
   assignUpdate(item){
      this.update.prenom=item.prenom
      this.update.nom=item.nom
      this.update.tel=item.tel_mobile
      this.update.email=item.email
      this.update.mdp=""
      this.update.adresse=item.adresse
      this.update.cp=item.cp
      this.update.ville=item.ville
      this.update.description=item.description
      this.update.statut=item.statut
      this.update.autorisation=item.autorisation
      this.update.ref_user=item.ref_user
    },
  async getAllUser(){
    if(this.$store.state.currentUser.user){
      this.arrayUser = await Users.getAll(
      {
      autorisation:this.$store.state.currentUser.user.autorisation,
      ref_user:this.$store.state.currentUser.user.ref_user
    }
    )
    }
    
   },
   async supprimer(){
     
      if(this.trash.save){
        let deleteSelected = 
        await Users.delete(
          {
            user_refs: this.selected,
          }
        )
        console.log("deleteSelected===>",deleteSelected)
        this.trash.save = !deleteSelected
        this.selected = []
        this.getAllUser(
        //   {autorisation:this.$store.state.currentUser.user.autorisation,
        // ref_user:this.$store.state.currentUser.user.ref_user}
        )
      }
    },
   deconnexion(){
    this.LogIn(false)
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.$router.push("/login");
   },
   async save(){
      if(this.vue=="creer" && this.create.save == true){
        const userPost = await Users.post({
          prenom: this.create.prenom,
          nom: this.create.nom,
          tel_mobile: this.create.tel,
          email: this.create.email,
          mdp: this.create.mdp,
          adresse: this.create.adresse,
          cp: this.create.cp,
          ville: this.create.ville,
          description: this.create.description,
          statut: this.create.statut,
          autorisation: this.create.autorisation,
        })

        if(userPost){
          Object.assign(this.$data.create, this.$options.data().create);
          this.getAllUser(
        //     {autorisation:this.$store.state.currentUser.user.autorisation,
        // ref_user:this.$store.state.currentUser.user.ref_user}
        )
        }
      }
      if(this.vue=="modifier" && this.update.save == true){
        const userUpdate = await Users.update({
          prenom: this.update.prenom,
          nom: this.update.nom,
          tel_mobile: this.update.tel,
          email: this.update.email,
          adresse: this.update.adresse,
          cp: this.update.cp,
          ville: this.update.ville,
          description: this.update.description,
          statut: this.update.statut,
          autorisation: this.update.autorisation,
          ref_user: this.update.ref_user,
        })
        let userUpdateMDP =null
        if(this.update.mdp!=""){
          userUpdateMDP = await Users.updateMDP({
            mdp: this.update.mdp,
            ref_user: this.update.ref_user,
        })
          
        }

        if(userUpdate || userUpdateMDP){
          this.vue="voir"
          this.$store.state.currentUser.user = this.update
          this.getAllUser(
        //     {autorisation:this.$store.state.currentUser.user.autorisation,
        // ref_user:this.$store.state.currentUser.user.ref_user}
        )
        this.update.save = false
        }
      }
    },
    controlSave(){
      if(this.vue=="creer" 
      && this.create.prenom!==""
      && this.create.nom!==""
      && this.create.tel!==""
      && this.create.email!==""
      && this.create.mdp!==""
      && this.create.adresse!==""
      && this.create.cp!==""
      && this.create.ville!==""
      && this.create.statut!==""
      && this.create.autorisation!==""){
        this.create.save = true
      }else{
        this.create.save = false
      }

      if(
        this.vue=='modifier' && (this.update.prenom!=this.itemUpdate.prenom && this.itemUpdate.prenom!=undefined ) ||
        (this.update.nom!=this.itemUpdate.nom && this.itemUpdate.nom!=undefined ) ||
        (this.update.tel!=this.itemUpdate.tel_mobile && this.itemUpdate.tel_mobile!=undefined ) ||
        (this.update.email!=this.itemUpdate.email && this.itemUpdate.email!=undefined ) ||
        (this.update.mdp!="" && this.itemUpdate.mdp!=undefined ) ||
        (this.update.adresse!=this.itemUpdate.adresse && this.itemUpdate.adresse!=undefined ) ||
        (this.update.cp!=this.itemUpdate.cp && this.itemUpdate.cp!=undefined ) ||
        (this.update.ville!=this.itemUpdate.ville && this.itemUpdate.ville!=undefined ) ||
        (this.update.description!=this.itemUpdate.description && this.itemUpdate.description!=undefined ) ||
        (this.update.statut!=this.itemUpdate.statut && this.itemUpdate.statut!=undefined ) ||
        (this.update.autorisation!=this.itemUpdate.autorisation && this.itemUpdate.autorisation!=undefined )
      ){
        this.update.save=true
      }else{
  
        this.update.save=false
      }
    },
    controlSelect(){
      if(this.selected.length && this.vue=="voir"){
        this.trash.save = true
      }else{
        this.trash.save = false
      }}
  }
  }
</script>
